// IMPORTS
import React from "react";
import Logo from "../assets/images/logo.svg";
import { capitalize } from "../helpers/hooks";
import { connectToChain, connectToWallet, mint } from "../helpers/web3";
// COMPONENT
const Mint = ({ props }) => {
  // STATE
  const { state, setState } = props;
  const { address, chain, owners, minted, message, link, throbbers } = state;
  const evm = process.env.REACT_APP_EVM;
  const correctChain = chain === capitalize(evm);
  const hasMinted = owners.includes(address?.toLowerCase());
  // TOGGLE THROBBER
  const toggleThrobber = (key, value) => {
    setState((_state) => ({
      ..._state,
      throbbers: { ..._state.throbbers, [key]: value },
    }));
  };
  // RENDER
  return (
    <div className="mintContainer">
      {/* LOGO */}
      <img className="mintLogo" alt="The Bored Brewing Company" src={Logo} />
      {/* TITLE */}
      <p className="mintTitle">
        Join your fellow <br /> apes in building <br /> web3's coolest <br />
        beer brand
      </p>
      {/* WALLET BUTTON */}
      <button
        className="mintWalletButton"
        style={{ display: address && "none" }}
        onClick={() => {
          toggleThrobber("wallet", true);
          connectToWallet().finally(() => toggleThrobber("wallet", false));
        }}
      >
        {`${throbbers.wallet ? "Connecting" : "Connect"} my wallet`}
      </button>
      {/* CHAIN BUTTON */}
      {address && (
        <button
          className="mintChainButton"
          style={{ display: correctChain && "none" }}
          onClick={() => {
            toggleThrobber("chain", true);
            connectToChain(address, evm).finally(() => {
              toggleThrobber("chain", false);
            });
          }}
        >
          {`${throbbers.chain ? "Connecting" : "Connect"} to ${evm}`}
        </button>
      )}
      {/* MINT BUTTON */}
      {address && correctChain && !hasMinted && !minted && (
        <button
          className="mintButton"
          onClick={async () => {
            toggleThrobber("mint", true);
            setState({ ...state, throbbers: { ...throbbers, mint: true } });
            const { success, message, link } = await mint(address, setState);
            setState({
              ...state,
              message,
              minted: success,
              link,
              throbbers: { ...throbbers, mint: false },
            });
          }}
        >
          {throbbers.mint ? "Minting" : "Mint"}
          {throbbers.mint && <span className="mintThrobber" />}
        </button>
      )}
      {/* MESSAGE */}
      {(hasMinted || message) && (
        <p className="mintMessage">
          {hasMinted ? "You've already minted" : message}
        </p>
      )}
      {/* LINK */}
      {link && (
        <a className="mintLink" href={link} target="_blank" rel="noreferrer">
          <span className="mintLinkInstructions">
            Click here for more information
          </span>
          <span style={{ overflowX: "scroll" }}>{link}</span>
        </a>
      )}
    </div>
  );
};
// EXPORT
export default Mint;
