// ETHEREUM
const ethereum = {
  chainId: "0x1", // 1
  chainName: "Ethereum Mainnet",
  nativeCurrency: { name: "Ether", symbol: "ETH", decimals: 18 },
  rpcUrls: [
    `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
    `wss://mainnet.infura.io/ws/v3/${process.env.REACT_APP_INFURA_KEY}`,
    "https://api.mycryptoapi.com/eth",
    "https://cloudflare-eth.com",
  ],
  blockExplorerUrls: ["https://etherscan.io"],
};
// GOERLI
const goerli = {
  chainId: "0x5", // 5
  chainName: "Goerli",
  nativeCurrency: { name: "Goerli Ether", symbol: "GoerliETH", decimals: 18 },
  rpcUrls: [
    "https://rpc.ankr.com/eth_goerli",
    `https://goerli.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
    "https://eth-goerli.public.blastapi.io",
  ],
  blockExplorerUrls: ["https://goerli.etherscan.io"],
};
// ETHEREUM CLASSIC
const ethereumClassic = {
  chainId: "0x3D", // 61
  chainName: "Ethereum Classic Mainnet",
  nativeCurrency: {
    name: "Ethereum Classic Ether",
    symbol: "ETC",
    decimals: 18,
  },
  rpcUrls: ["https://www.ethercluster.com/etc"],
  blockExplorerUrls: ["https://blockscout.com/etc/mainnet"],
};
// POLYGON
const polygon = {
  chainId: "0x89", // 137
  chainName: "Polygon Mainnet",
  nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  rpcUrls: [
    "https://polygon-rpc.com/",
    "https://rpc-mainnet.matic.network",
    "https://matic-mainnet.chainstacklabs.com",
    "https://rpc-mainnet.maticvigil.com",
    "https://rpc-mainnet.matic.quiknode.pro",
    "https://matic-mainnet-full-rpc.bwarelabs.com",
  ],
  blockExplorerUrls: ["https://polygonscan.com"],
};
// MUMBAI
const mumbai = {
  chainId: "0x13881", // 80001
  chainName: "Polygon Mumbai Testnet",
  nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  rpcUrls: [
    "https://matic-mumbai.chainstacklabs.com",
    "https://rpc-mumbai.maticvigil.com",
    "https://matic-testnet-archive-rpc.bwarelabs.com",
  ],
  blockExplorerUrls: ["https://mumbai.polygonscan.com"],
};
// OPTIMISM
const optimism = {
  chainId: "0xA", // 10
  chainName: "Optimism",
  nativeCurrency: {
    name: "Ether",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["https://mainnet.optimism.io/"],
  blockExplorerUrls: ["https://optimistic.etherscan.io"],
};
// SHIBA
const shiba = {
  chainId: "0x1B", // 27
  chainName: "ShibaChain",
  nativeCurrency: {
    name: "SHIBA INU COIN",
    symbol: "SHIB",
    decimals: 18,
  },
  rpcUrls: ["https://rpc.shibachain.net"],
  blockExplorerUrls: ["https://exp.shibachain.net"],
};
// BINANCE
const binance = {
  chainId: "0x38", // 56
  chainName: "Binance Smart Chain Mainnet",
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: [
    "https://bsc-dataseed1.binance.org",
    "https://bsc-dataseed2.binance.org",
    "https://bsc-dataseed3.binance.org",
    "https://bsc-dataseed4.binance.org",
    "https://bsc-dataseed1.defibit.io",
    "https://bsc-dataseed2.defibit.io",
    "https://bsc-dataseed3.defibit.io",
    "https://bsc-dataseed4.defibit.io",
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed2.ninicoin.io",
    "https://bsc-dataseed3.ninicoin.io",
    "https://bsc-dataseed4.ninicoin.io",
    "wss://bsc-ws-node.nariox.org",
  ],
  blockExplorerUrls: ["https://bscscan.com"],
};
// GANACHE
const ganache = {
  chainId: "0x539", // 1337
  chainName: "Ganache",
  nativeCurrency: {
    name: "Ganache ETH",
    symbol: "ETH",
    decimals: 18,
  },
  rpcUrls: ["http://localhost:7545"],
  blockExplorerUrls: null,
};
// CHAINS
const chains = {
  ethereum,
  goerli,
  ethereumClassic,
  polygon,
  mumbai,
  optimism,
  shiba,
  binance,
  ganache,
};
// CHAIN INDEX
const chainIndex = {
  "0x1": "Ethereum",
  "0x5": "Goerli",
  "0x3D": "Ethereum Classic",
  "0x89": "Polygon",
  "0x13881": "Mumbai",
  "0xA": "Optimism",
  "0x1B": "ShibaChain",
  "0x38": "Binance Smart Chain",
  "0x539": "Ganache",
};
// EXPORT
export { chains, chainIndex };
