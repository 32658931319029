// IMPORTS
import { ethers } from "ethers";
import { chains, chainIndex } from "./chains";
import { emitError, encrypt, fetchAPI } from "./hooks";
// LOOK FOR WALLET
const lookForWallet = async (callback) => {
  try {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const accounts = await provider.send("eth_accounts", []);
      callback(accounts[0]);
      window.ethereum.on("accountsChanged", (_accounts) => {
        callback(_accounts[0]);
      });
    }
  } catch (error) {
    emitError("LOOK FOR WALLET", error);
  }
};
// LOOK FOR CHAIN
const lookForChain = async (callback) => {
  try {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const chainID = await provider.send("eth_chainId", []);
      chainID && callback(chainIndex[chainID]);
      window.ethereum.on("chainChanged", (_chainID) => {
        chainID && callback(chainIndex[_chainID]);
      });
    }
  } catch (error) {
    emitError("LOOK FOR CHAIN", error);
  }
};
// CONNECT TO WALLET
const connectToWallet = async () => {
  try {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
    } else {
      window.open("https://metamask.io/download/", "_blank");
    }
  } catch (error) {
    emitError("CONNECT TO WALLET", error);
  }
};
// CONNECT TO CONTRACT
const connectToContract = async (withSignature) => {
  try {
    if (window.ethereum) {
      const contract = (address, abi, provider) => {
        if (withSignature) {
          const signer = provider.getSigner();
          return new ethers.Contract(address, abi, signer);
        } else {
          return new ethers.Contract(address, abi, provider);
        }
      };
      switch (process.env.REACT_APP_EVM) {
        case "goerli": {
          const { test } = await import("./contracts");
          const { address, abi } = test;
          const provider = new ethers.providers.Web3Provider(
            window.ethereum,
            "goerli"
          );
          return contract(address, abi, provider);
        }
        case "ethereum": {
          const { prod } = await import("./contracts");
          const { address, abi } = prod;
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          return contract(address, abi, provider);
        }
        case "ganache": {
          const Contract =
            process.env.REACT_APP_EVM === "ganache"
              ? await import("../contracts/F50.json")
              : null;
          const address = Contract.networks[5777].address;
          const abi = Contract.abi;
          const provider = new ethers.providers.JsonRpcProvider(
            "http://localhost:7545"
          );
          return contract(address, abi, provider);
        }
        default:
          return null;
      }
    }
  } catch (error) {
    emitError("CONNECT TO CONTRACT", error);
  }
};
// CONNECT TO CHAIN
const connectToChain = async (userAddress, chain) => {
  try {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: chains[chain].chainId }],
        });
      } catch (error1) {
        if (error1.code === 4902) {
          const {
            chainId,
            chainName,
            nativeCurrency,
            rpcUrls,
            blockExplorerUrls,
          } = chains[chain];
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId,
                chainName,
                nativeCurrency,
                rpcUrls,
                blockExplorerUrls,
              },
              userAddress,
            ],
          });
        } else {
          emitError("CONNECT TO CHAIN 1", error1);
        }
      }
    }
  } catch (error2) {
    emitError("CONNECT TO CHAIN 2", error2);
  }
};
// CHECK MINT STATUS
const checkMintStatus = (setState) => {
  const checkMintLoop = setInterval(() => {
    const tx_hash = localStorage.getItem("tx_hash");
    if (tx_hash) {
      const isEthereum = process.env.REACT_APP_EVM === "ethereum";
      const URL = `https://api${
        isEthereum ? "" : `-${process.env.REACT_APP_EVM}`
      }.etherscan.io/api?module=transaction&action=gettxreceiptstatus&txhash=${tx_hash}&apikey=${
        process.env.REACT_APP_ETHERSCAN_KEY
      }`;
      const request = new Request(URL, {
        method: "GET",
        headers: new Headers({
          "Content-Type": "application/json",
          Accept: "application/json",
        }),
      });
      fetch(request)
        .then((response) => response.json())
        .then((response) => {
          if (response.result.status === "1") {
            setState((_state) => ({
              ..._state,
              message: "Your mint was successful",
              link: `https://${
                isEthereum ? "" : `${process.env.REACT_APP_EVM}.`
              }etherscan.io/tx/${tx_hash}`,
            }));
            localStorage.removeItem("tx_hash");
            clearInterval(checkMintLoop);
          }
        });
    }
  }, 5000);
};
// MINT
const mint = async (address, setState) => {
  try {
    if (window.ethereum) {
      const contract = await connectToContract(true);
      const tokenPrice = await contract.tokenPrice();
      const value = ethers.utils.parseUnits(tokenPrice.toString(), "wei");
      const result = await fetchAPI(`/merkle/${address}`);
      if (result.proof.length > 0) {
        const tx = await contract.mint(result.proof, { value });
        const isEthereum = process.env.REACT_APP_EVM === "ethereum";
        localStorage.setItem("tx_hash", tx.hash);
        checkMintStatus(setState);
        return {
          success: true,
          message: `Your mint is being processed`,
          link: `https://${
            isEthereum ? "" : `${process.env.REACT_APP_EVM}.`
          }etherscan.io/tx/${tx.hash}`,
        };
      } else {
        return {
          success: true,
          message: "You're not eligible to mint at this time",
          link: null,
        };
      }
    }
  } catch (error) {
    const errorMessage = encrypt(error.toString()).replaceAll("/", "~");
    fetchAPI(`/handle-error/${address}/${errorMessage}`);
    if (error?.code === "INSUFFICIENT_FUNDS") {
      return { success: false, message: "Insufficient funds", link: null };
    } else {
      return {
        success: false,
        message: "An error has occured. Please try again",
        link: null,
      };
    }
  }
};
// EXPORTS
export {
  lookForWallet,
  lookForChain,
  connectToWallet,
  connectToContract,
  connectToChain,
  checkMintStatus,
  mint,
};
