// IMPORTS
import "./styles/App.scss";
import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { getOwners } from "./helpers/moralis";
import { lookForChain, lookForWallet, checkMintStatus } from "./helpers/web3";
import Login from "./pages/Login";
import Mint from "./pages/Mint";
// COMPONENT
const App = () => {
  // STATE
  const [state, setState] = useState({
    address: null,
    chain: null,
    owners: [],
    loggedIn: false,
    password: "",
    minted: false,
    message: null,
    link: null,
    throbbers: { wallet: false, chain: false, mint: false },
  });
  const loaded = useRef(false);
  const props = { state, setState };
  // LIFE CYCLE
  useEffect(() => {
    if (!loaded.current) {
      lookForWallet((address) => {
        setState((_state) => ({ ..._state, address }));
      });
      lookForChain((chain) => {
        setState((_state) => ({ ..._state, chain }));
      });
      getOwners((owners) => {
        setState((_state) => ({ ..._state, owners }));
      });
      const tx_hash = localStorage.getItem("tx_hash");
      tx_hash && checkMintStatus(setState);
      loaded.current = true;
    }
  }, []);
  // RENDER
  return (
    <div className="appContainer">
      {/* LOGIN */}
      <Login props={props} />
      {/* MINT */}
      {state.loggedIn && <Mint props={props} />}
    </div>
  );
};
// INITIALIZE
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
