// IMPORTS
import React, { useState, useCallback, useEffect } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import BoredBishop from "../assets/images/bored-bishop.svg";
// COMPONENT
const Login = ({ props }) => {
  // STATE
  const { state, setState } = props;
  const { loggedIn, password } = state;
  const [showPassword, setShowPassword] = useState(false);
  const [shaking, setShaking] = useState(false);
  // CHECK PASSWORD
  const checkPassword = useCallback(() => {
    if (password === process.env.REACT_APP_PASSWORD) {
      setState((_state) => ({ ..._state, loggedIn: true, password: "" }));
    } else {
      setShaking(true);
      setTimeout(() => setShaking(false), 500);
    }
  }, [password, setState]);
  // LIFE CYCLE
  useEffect(() => {
    const keydown = (event) => event.code === "Enter" && checkPassword();
    window.addEventListener("keydown", keydown);
    return () => {
      window.removeEventListener("keydown", keydown);
    };
  }, [checkPassword]);
  // RENDER
  return (
    <div className={loggedIn ? "loginContainerMove" : "loginContainer"}>
      {/* BORED BISHOP */}
      <img className="loginBoredBishop" alt="Bored Bishop" src={BoredBishop} />
      {/* CONTENT */}
      <div className="loginContent">
        {/* SPEECH BUBBLE */}
        <div className="loginSpeechBubble">
          Hey, you! Enter <br /> the password for <br /> the good *^%$
        </div>
        {/* INPUT */}
        <div className="loginInputContainer">
          <input
            className="loginInput"
            style={{ paddingRight: showPassword && "5rem" }}
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            autoComplete="off"
            value={state.password}
            onChange={(event) => {
              setState({ ...state, password: event.target.value });
            }}
          />
          <button
            className="loginInputToggleButton"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        {/* LOGIN BUTTON */}
        <button
          className={shaking ? "loginShakingButton" : "loginButton"}
          onClick={() => checkPassword(state.password)}
        >
          Let Me In
        </button>
      </div>
    </div>
  );
};
// EXPORT
export default Login;
