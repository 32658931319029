// IMPORTS
import CryptoJS from "crypto-js";
import { ethers } from "ethers";
// EMIT ERROR
const emitError = (message, error) => {
  console.log(`${message} ERROR :: `, error);
};
// RUN
const run = (key, value) => {
  try {
    const data = value();
    return [true, data];
  } catch (error) {
    emitError(`${key} RUN`, error);
    return [false, error];
  }
};
// ENCRYPT
const encrypt = (value) => {
  const [success, data] = run("ENCRYPT", () => {
    return CryptoJS.AES.encrypt(
      JSON.stringify(value),
      process.env.REACT_APP_KEY
    ).toString();
  });
  return success ? data : null;
};
// DECRYPT
const decrypt = (value) => {
  const [success, data] = run("DECRYPT", () => {
    return JSON.parse(
      CryptoJS.AES.decrypt(value, process.env.REACT_APP_KEY).toString(
        CryptoJS.enc.Utf8
      )
    );
  });
  return success ? data : null;
};
// CAPITALIZE
const capitalize = (string) => {
  const [success, data] = run("CAPITALIZE", () => {
    const word = string.toLowerCase();
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return success ? data : null;
};
// CONVERT ETH
const convertETH = (key, value, format) => {
  const [success, data] = run("FORMAT ETH", () => {
    const number =
      typeof value === "object" || typeof value === "number"
        ? value.toString()
        : value;
    return format
      ? parseFloat(ethers.utils.formatUnits(number, key))
      : ethers.utils.parseUnits(number, key);
  });
  return success ? data : null;
};
// FETCH API
const fetchAPI = (endpoint) => {
  const [success, data] = run("FETCH API", async () => {
    const URL =
      process.env.REACT_APP_BRANCH === "dev"
        ? `http://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_SERVER_PORT}${endpoint}`
        : process.env.REACT_APP_BRANCH === "prod"
        ? `https://theboredbrewingcompany.com${endpoint}`
        : null;
    const request = new Request(URL, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json",
      }),
    });
    return await fetch(request)
      .then((response) => response.json())
      .then((response) => response);
  });
  return success ? data : null;
};
// EXPORTS
export { emitError, encrypt, decrypt, capitalize, convertETH, fetchAPI };
