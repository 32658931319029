// IMPORTS
import { emitError } from "./hooks";
// ENDPOINTS
const endpoints = {
  getNFTz: (address, chain, cursor) =>
    `https://deep-index.moralis.io/api/v2/${address}/nft?chain=${chain}&format=decimal&limit=100${
      cursor ? `&cursor=${cursor}` : ""
    }`,
  getNFTzForContract: (address, chain, cursor) =>
    `https://deep-index.moralis.io/api/v2/${address.user}/nft/${
      address.contract
    }?chain=${chain}&format=decimal&limit=100${
      cursor ? `&cursor=${cursor}` : ""
    }`,
  getOwners: (address, chain, cursor) =>
    ` https://deep-index.moralis.io/api/v2/nft/${address}/owners?chain=${chain}&format=decimal&limit=100${
      cursor ? `&cursor=${cursor}` : ""
    }`,
};
// CALL MORALIS
const callMoralis = async (method, address, chain) => {
  try {
    let result = [];
    const fetchMoralis = async (cursor) => {
      const request = new Request(endpoints[method](address, chain, cursor), {
        method: "GET",
        headers: new Headers({
          Accept: "application/json",
          "X-API-Key": process.env.REACT_APP_MORALIS_KEY,
        }),
      });
      await fetch(request)
        .then((response) => response.json())
        .then(async (response) => {
          result = [...result, ...response.result];
          response.cursor && (await fetchMoralis(response.cursor));
        });
    };
    await fetchMoralis();
    return result;
  } catch (error) {
    emitError("CALL MORALIS", error);
  }
};
// GET OWNERS
const getOwners = async (callback) => {
  try {
    let result;
    switch (process.env.REACT_APP_EVM) {
      case "ganache": {
        result = [
          // { owner_of: "0x525934060A88344D4917CCbC5E52C936Ea199731" }
        ];
        break;
      }
      case "goerli": {
        const { test } = await import("./contracts");
        const { address } = test;
        result = await callMoralis("getOwners", address, "goerli");
        break;
      }
      case "ethereum": {
        const { prod } = await import("./contracts");
        const { address } = prod;
        result = await callMoralis("getOwners", address, "eth");
        break;
      }
      default:
        break;
    }
    const owners = result
      ? result.map(({ owner_of }) => owner_of.toLowerCase())
      : [];
    callback(owners);
  } catch (error) {
    emitError("GET OWNERS", error);
  }
};
// EXPORT
export { getOwners };
